<template>
  <div id="questionDetail">
    <div class="clearfix"></div>
    <div class="ms-cell-question">
      <div class="ms-cell-ft">
        <div class="ms-user">
          <div class="ms-user-avatar">
            <img v-if="questionDetail.avatar" :src="questionDetail.avatar" />
            <img v-else src="@/assets/img/live/userLogo.png" alt="" />
          </div>
          <div class="ms-user-name">
            {{ questionDetail.username ? questionDetail.username : "匿名用户" }}
          </div>
          <div class="ms-user-time">{{ questionDetail.createDate }}</div>
        </div>
      </div>

      <div style="line-height: 22px; margin: 10px 0">
        <div style="display: inline-block">
          <div class="listType">面经题</div>
        </div>
        <div class="ms-cell-data">
          <span style="margin-right: 12px">
            <span class="iconfont icon-liulan"></span
            >{{ questionDetail.pageViews }}
          </span>

          <span style="margin-right: 12px"
            ><span
              class="iconfont icon-dianzan"
              style="margin-right: 4px"
            ></span
            >{{ questionDetail.likeNums }}
          </span>
          <span>
            <span class="iconfont icon-huifu" style="margin-right: 4px"></span
            >{{ questionDetail.commentNums }}
          </span>
        </div>
      </div>

      <div class="ms-cell-hd">
        <div class="ms-cell-tt">
          {{ questionDetail.interviewQuestions }}
        </div>
        <div class="companyName">
          {{ questionDetail.companyName }}
        </div>
      </div>

      <div
        class="imageContainer"
        v-if="questionDetail.pictures && questionDetail.pictures.length > 0"
      >
        <el-image
          v-for="(image, index) in questionDetail.pictures"
          style="width: 100px; height: 100px"
          :src="image"
          :key="index"
          @click="
            showIndex = index;
            showViewer = true;
          "
        />
      </div>
      <div style="text-align: center; margin: 10px 0">
        <el-button size="mini" @click="likeComments(0)">点赞</el-button>
      </div>
      <el-image-viewer
        v-if="showViewer"
        :initial-index="showIndex"
        :url-list="questionDetail.pictures"
        :on-close="
          () => {
            showViewer = false;
          }
        "
        :z-index="99999"
      />
    </div>
    <Comments :type="2" :comment-total="questionDetail.commentNums"></Comments>
    <Reply
      v-if="userInfo && userInfo.id"
      :type="2"
      @reloadDetail="getQuestionDetail"
    ></Reply>
  </div>
</template>
<script>
import { getQuestionDetail, publishComment } from "@/service/company";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { Toast } from "vant";
import Reply from "@/components/Reply";
import Comments from "@/components/Comments";
import { mapState } from "vuex";
export default {
  name: "QuestionDetail",
  data() {
    return {
      questionDetail: {},
      showViewer: false,
      showIndex: 0,
    };
  },
  components: { ElImageViewer, Reply, Comments },
  methods: {
    getQuestionDetail() {
      getQuestionDetail(this.$route.query.id).then((res) => {
        if (res.success) {
          this.questionDetail = res.result;
          document.title = `${this.questionDetail.interviewQuestions} - 专注北美算法面试 - CSON`;
        }
      });
    },
    likeComments(commentId) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/login");
        return;
      }
      const data = {
        superType: 2,
        type: false,
        superId: this.$route.query.id,
        parentId: commentId,
      };
      publishComment(data).then((res) => {
        if (res.success) {
          Toast("点赞成功！");
          this.getQuestionDetail();
        } else {
          Toast("已点过赞");
        }
      });
    },
  },
  mounted() {
    this.getQuestionDetail();
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>
<style lang="scss" scoped>
#questionDetail {
  margin-top: 54px;
  background: #f8f8f8;
}
.ms-cell-question {
  padding: 24px;
  width: calc(100% - 68px);
  margin: 10px;
  background: #fff;
}
.clearfix {
  clear: both;
  height: 1px;
}
.ms-user-avatar {
  width: 24px;
  height: 24px;
  display: inline-block;
  img {
    width: 100%;
    vertical-align: top;
  }
}
.ms-user-name {
  display: inline-block;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #34495e;
  line-height: 24px;
  margin-left: 6px;
  vertical-align: top;
}
.ms-user-time {
  display: inline-block;
  margin-left: 6px;
  vertical-align: top;
  line-height: 24px;
  font-size: 12px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #898989;
}

.listType {
  display: inline-block;
  background: rgba(250, 100, 0, 0.2);
  border-radius: 2px;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #fa6400;
  line-height: 18px;
  padding: 2px 8px;
  margin-right: 12px;
  &.blue {
    color: #409eff;
    background-color: #ecf5ff;
    border-color: #d9ecff;
  }
}
.ms-cell-data {
  float: right;
  font-size: 12px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #34495e;
  .iconfont {
    vertical-align: top;
  }
}
.ms-cell-tt {
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #34495e;
  line-height: 20px;
  margin-bottom: 14px;
}
.ms-group-hd {
  font-size: 12px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #34495e;
  line-height: 12px;
}
.ms-group-bd {
  font-size: 12px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #969696;
  line-height: 16px;
}
.imageContainer {
  padding-top: 24px;
  padding-bottom: 24px;
}
::v-deep .imageContainer .el-image {
  margin-right: 12px;
  margin-bottom: 12px;
}
.ms-type {
  font-size: 12px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  line-height: 18px;
}
.orange {
  color: #fa6400 !important;
}

.blue {
  color: #2772db !important;
}

.green {
  color: #2ec1ac !important;
}

.red {
  color: #e02020 !important;
}
.companyName {
  font-size: 12px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #fa6400;
  line-height: 12px;
}
</style>